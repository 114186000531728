import React from 'react'
import SEO from '../components/seo'
import CardPost from '../components/card-post'
import CardReference from '../components/card-reference'
import { Container, Row, Col } from 'react-grid-system'
import Cover from '../components/cover'
import styled from 'styled-components'
import VariableStyle from '../components/VariableStyle'
import Text from '../components/text'
import { Link } from 'gatsby'
import { up } from 'styled-breakpoints'
import Banner from '../components/banner'

const NavLink = props => {
  if (!props.test) return <Col xs='content'><Link to={props.url}><Item tag='div' size='32'>{props.text}</Item></Link></Col>
  else return null
}

const Wrapper = styled.div`
  margin-bottom: 50px;

  ${up('lg')} { margin-bottom: 100px; }
`

const Categories = styled.div`
  border: 1px solid ${props => props.theme.colors.green};
  border-left: none;
  border-right: none;
  padding: 15px 0;
  margin-bottom: 50px;
  margin-top: 50px;

  ${up('lg')} { margin-top: 0; }
`

const PrevNext = styled.div`
  border: 1px solid ${props => props.theme.colors.green};
  border-left: none;
  border-right: none;
  padding: 15px 0;
  margin-top: 15px;
  display: ${props => props.show ? `block` : `none`};

  ${up('lg')} { margin-top: 130px; }
`

const Item = styled(Text)`
  cursor: pointer;
  padding: 15px 0;
  ${VariableStyle.GreenText}
  ${VariableStyle.StrokeText}
  transition: color 0.4s ${props => props.theme.eases.out};

  ${props => props.active && `
    color: ${props.theme.colors.green} !important;
    opacity: 1 !important;
  `}

  &:hover { color: ${props => props.theme.colors.green} !important; }
`

const ItemCategory = styled(Item)`
  body.isMobile & { opacity: 0.4; }
`

const Separator = styled.div`
  display: block;
  width: 100%;
  height: 0;
  position: relative;

  ${up('lg')} { margin-top: 175px; }

  ${up('xxl')} { margin-top: 250px; }
`
const Posts = (props) => {
  const { group, index, nodes, first, last, additionalContext, postsUri, currentCategory, options, type } = props.pageContext
  const previousUrl = index - 1 === 1 ? additionalContext.uri : `${additionalContext.uri}${(index - 1).toString()}`
  const nextUrl = `${additionalContext.uri}${(index + 1).toString()}`

  return (
    <>
      <SEO data={additionalContext.seo} />
      <div className="posts">
        {props.pageContext.featuredImage && <Cover title={props.pageContext.title} image={props.pageContext.featuredImage.node}></Cover>}
        <Wrapper>
          <Container fluid data-scroll-section>
            <Row>
              <Col lg={20} offset={{ lg: 2 }}>
                <Categories>
                  <Row>
                    <Col xs='content'><Link to={postsUri}><ItemCategory active={!currentCategory} tag='div' size='32'>{type === 'reference' ? `Toutes nos réalisations`: `Toutes les catégories`}</ItemCategory></Link></Col>
                    {nodes && nodes.map((category, index) => {
                      if (category.items.nodes.length) { return (
                        <Col xs='content' key={index}>
                          <Link to={category.uri}>
                            <ItemCategory tag='div' size='32' active={currentCategory ? currentCategory.id === category.id : false}>{category.name}</ItemCategory>
                          </Link>
                        </Col>
                      )} else return (<></>)
                    })}
                  </Row>
                </Categories>
              </Col>
              {type === 'reference' && <Separator/>}
              {group && group.map((item, i) => {
                if (type === 'post') { return (
                  <Col lg={20} offset={{ lg: 2 }} key={i}>
                    <CardPost {...item} index={i} />
                  </Col>
                )} else if (type === 'reference') { return (
                  <Col lg={9} offset={{ lg: 2 }} key={i}>
                    <CardReference {...item} index={i} position={i % 2 === 0 ? 'left' : 'right'}/>
                  </Col>
                )} else return (<></>)
              })}
              <Col lg={20} offset={{ lg: 2 }}>
                <PrevNext show={!(first && last)}>
                  <Row justify='center'>
                    <NavLink test={first} url={previousUrl} text="Page précédente" />
                    <NavLink test={last} url={nextUrl} text="Page suivante" />
                  </Row>
                </PrevNext>
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Banner {...options.footerBanner}></Banner>
      </div>
    </>
  )
}

export default Posts